<template>
  <div>
    <b-card>
      <!-- Utils -->
      <b-row class="mb-2">
        <b-col cols="3">
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            :config="{ mode: 'range' }"
          />
        </b-col>
        <b-col>
          <b-button variant="primary"> Cerca </b-button>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-table :items="mappedInvoices" :fields="fields" striped responsive>
        <template #cell(type)="data">
          <b-badge
            v-if="
              getInvoiceSerial(data.item.localizator) ===
              AGENCY_CLIENT_INVOICES_SERIES
            "
            variant="success"
          >
            Client (subarrendament)
          </b-badge>
          <b-badge
            v-if="
              getInvoiceSerial(data.item.localizator) ===
              AGENCY_OWNER_INVOICES_SERIES
            "
            variant="warning"
          >
            Propietari (comissiò)
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            @click="onButtonClicked(data.item.uuid)"
          >
            Veure
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BTable, BButton, BRow, BCol, BCard, BBadge } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { formatDateStringToDate, formatCurrency } from "@/utils/formatters";
import {
  AGENCY_CLIENT_INVOICES_SERIES,
  AGENCY_OWNER_INVOICES_SERIES,
} from "@appConfig";

export default {
  components: {
    BTable,
    BButton,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rangeDate: null,
      fields: [
        { key: "localizator", label: "Factura" },
        { key: "accommodationName", label: "Propietat" },
        { key: "emittedAt", label: "Data" },
        { key: "pvpPrice", label: "Total" },
        { key: "type", label: "Tipus" },
        { key: "actions", label: "Accions" },
      ],
      AGENCY_CLIENT_INVOICES_SERIES,
      AGENCY_OWNER_INVOICES_SERIES,
    };
  },
  computed: {
    invoices() {
      return this.$store.getters["invoices/invoices"];
    },
    mappedInvoices() {
      if (!this.invoices.length) return [];
      return this.invoices.map((invoice) => ({
        localizator: invoice.localizator,
        accommodationName: invoice.accommodationName,
        emittedAt: formatDateStringToDate(invoice.emittedAt),
        pvpPrice: formatCurrency(invoice.pvpPrice),
        uuid: invoice.uuid,
      }));
    },
  },
  created() {
    this.fetchInvoices();
  },
  methods: {
    onButtonClicked(uuid) {
      this.$router.push({
        name: "foravila-accounting-billing-invoice-details",
        params: {
          invoiceUuid: uuid,
        },
      });
    },
    fetchInvoices() {
      this.$store.dispatch("invoices/fetchInvoices");
    },
    getInvoiceSerial(localizator) {
      return localizator.replace(/[0-9]/g, "");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
